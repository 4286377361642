<template>
  <div class="header-menu__before"
    v-if="isOpenMenu"
    @click.self="closeMenu">
    <transition name="slide-fade" >
    <!-- <div class="headerifactive" ></div> -->
      <div
        class="header-menu"
        :class="{ 'is-opened': isOpenMenu }"
        v-if="!$isMobile()"
      >
      <img class="header-menu-close" v-if="isOpenMenu" @click="closeMenu" src="@/assets/img/header-menu/close.svg">
        <div class="container">
          <div class="header-menu__account">
            <div class="header-menu__account__login" >
              <div class="header-menu__account__login-block">
                <h2 class="header-menu__account__login-title">Войдите в профиль</h2>
                <p class="header-menu__account__login-subtitle">после входа в профиль вам будет доступно:</p>
                <ul class="header-menu__account__login-advantages">  
                  <li class="header-menu__account__login-advantages__item percent">участие в акциях и розыгрышах крутых призов</li>
                  <li class="header-menu__account__login-advantages__item bonus">оплата покупок накапливаемыми бонусами</li>
                  <li class="header-menu__account__login-advantages__item discount">горячие скидки на алкоголь и табачную продукцию</li>
                </ul>
              </div>
              <button @click.prevent="closeOpen"  class="header-menu__account__login-button">Войти</button>
            </div>
          </div>
          <div class="header-menu__links">
            <locale-router-link to='news' class="header-menu__links-item">Новости</locale-router-link>
            <locale-router-link to='news' class="header-menu__links-item">Полезные статьи</locale-router-link>
            <locale-router-link to='news' class="header-menu__links-item">О клубе</locale-router-link>
            <locale-router-link to='news' class="header-menu__links-item">Правила Клуба</locale-router-link>
          </div>
          <div class="header-menu__buttons">
            <button class="header-menu__buttons-item">Каталог скидок</button>
          </div> 
        </div>  
      </div>
      <div class="header-menu-mobile" v-else>
        <div class='header-menu-mobile__header'>
          <img class="header-menu-mobile__header-close" @click="closeMenu" src="@/assets/img/header-menu/crossmobile.svg">
          <div class="header-menu-mobile__header-items">
            <img class="header-menu-mobile__header-items-img" src="@/assets/img/header-menu/gps.svg">
            <h2 class="header-menu-mobile__header-items-title"> АЛА</h2>
            <span class="header-menu-mobile__header-items-divider"> | </span>
            <h2 class="header-menu-mobile__header-items-title"> RU </h2>
          </div>
        </div>  
        <div class="header-menu-mobile__profile" @click="profileInfoOpen = !profileInfoOpen">
          <div class="header-menu-mobile__profile__title">
            <img class="header-menu-mobile__profile__title-img" src="@/assets/img/header-menu/nouser.svg">
            <div class="header-menu-mobile__profile__title__profile">
              <h2 class="header-menu-mobile__profile__title__profile-name">Войдите в профиль</h2>
              <p class="header-menu-mobile__profile__title__profile-subtitle">после входа в профиль вам будет доступно:</p>
            </div>
            <img src="@/assets/img/header-menu/down.svg" class="header-menu-mobile__profile__title-down">
          </div>
          <div class="header-menu-mobile__profile__content" v-if="profileInfoOpen">
            <div  :class="{'slideDown': profileInfoOpen }">
            <ul class="header-menu-mobile__profile__content-list">  
              <li class="header-menu-mobile__profile__content-list-item">
                <img src="@/assets/img/header-menu/percent.svg">участие в акциях и розыгрышах крутых призов
              </li>
              <li class="header-menu-mobile__profile__content-list-item">
                <img src="@/assets/img/header-menu/bonuses.svg"> 
                оплата покупок накапливаемыми бонусами
              </li>
              <li class="header-menu-mobile__profile__content-list-item">
                <img src="@/assets/img/header-menu/discount.svg">
                горячие скидки на алкоголь и табачную продукцию              
              </li>
            </ul>
              <button @click.prevent="closeOpen" class="header-menu-mobile__profile__content-button">Войти</button>
            </div>
          </div>
        </div>
        <div class="header-menu-mobile__accordions">
          <div class="header-menu-mobile-accordion" v-for="first in itemss" :key="first.id">
            <h2 class="header-menu-mobile-accordion-title" @click="first.isOpen = !first.isOpen">
              {{first.name}}
              <img src="@/assets/img/header-menu/down.svg" :class="{'rotata': first.isOpen}">
            </h2>
            <div class="header-menu-mobile-accordion__next"  v-if="first.itemsss.length != 0 && first.isOpen" >
              <div v-for="second in first.itemsss" :key="second.id" > 
                <h2 class="header-menu-mobile-accordion__next-title" @click="second.isOpen = !second.isOpen">
                  {{second.name}}
                  <img src="@/assets/img/header-menu/down.svg" :class="{'rotata': first.isOpen}">
                </h2>
                <div class="header-menu-mobile-accordion__next__last" v-if="second.items.length != 0 && second.isOpen">
                  <h2 v-for="last in second.items" :key="last.id">{{last.name}}</h2>
                </div>
              </div>
            </div>
            <div v-else-if='first.itemsss.length === 0 && first.isOpen'>
              <h2 class="header-menu-mobile-accordion__isempty">В этой категории пусто</h2>
            </div>
          </div>
        </div>
        <div class="header-menu-mobile__links">
          <locale-router-link class="header-menu-mobile__links-link" style="color: #12B1EB;">Скидка</locale-router-link>
          <locale-router-link class="header-menu-mobile__links-link" style="color: #FF594B;">Акции</locale-router-link>
          <locale-router-link class="header-menu-mobile__links-link" :to="heating-systems">Системы нагревания</locale-router-link>
          <locale-router-link class="header-menu-mobile__links-link">Готовим коктейли</locale-router-link>
          <locale-router-link class="header-menu-mobile__links-link">Бренды</locale-router-link>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getSocialLink } from "@/api/links";
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store";
import LocaleRouterLink from "../language/LocaleRouterLink.vue";
export default 
  {
  components: { LocaleRouterLink },
  name: "AppMenu",
  data() {
    return {
      social: null,
      profileInfoOpen: false,
      itemss: [
        {
          id: 1,
          name: 'Винная карта',
          isOpen: false,
          itemsss: [
            {
              id: 1,
              name: 'Страна',
              isOpen: false,
              items: [{
                id: 1,
                name: 'Красное'
              },{
                id: 2,
                name: 'Белое'
              }],
            },
            {
              id: 2,
              name: 'Вид',
              isOpen: false,
              items: [{
                id: 1,
                name: 'Красное'
              },{
                id: 2,
                name: 'Белое'
              }]
            }
          ]
        },
        {
          id: 2,
          name: 'Крепкий алкоголь',
          isOpen: false,
          itemsss: []
        }
      ],
    };
  },
  computed: {
    ...mapState(["isOpenMenu"]),
    ...mapState("cityModule", ["selectedCity"]),
  },
  methods: {
    ...mapActions({
      closeMenu: actionTypes.changeIsOpenMenu,
      openMenu: actionTypes.changeIsOpenMenu,
      openSelectCity: actionTypes.changeIsOpenSelectCity,
      openIsAuthActive: actionTypes.changeIsAuthActive,
    }),
    closeOpen(){
      this.closeMenu();
      this.openIsAuthActive()
    },
  },
  watch: {
    "$route.path"() {
      if (this.isOpenMenu) {
        this.closeMenu();
      }
    },
  },
  created() {
    getSocialLink().then((social) => {
      this.social = social;
    });
  },
};
</script>

<style lang="scss" scoped></style>
